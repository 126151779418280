<template>
  <div class="min-h-dvh flex flex-col" :style="{ paddingTop: 'env(safe-area-inset-top)' }">
    <!-- Header -->
    <FrontendMainNav :show-links="false" simple-mobile-nav />

    <!-- Page content -->
    <main role="main" class="flex flex-col grow">
      <slot />
    </main>

    <!-- Toasts -->
    <UNotifications />

    <!-- Footer -->
    <!-- <FrontendFooter /> -->

    <!-- native version popup -->
    <NativeUpdateModal v-if="isNative" />
  </div>
</template>

<script lang="ts" setup>
const { isNative } = useRuntimeConfig().public
</script>
